import React from "react"
import style from "../vdp.module.scss"

export default function useIncentives(incentivesList = [], Pricing) {

  const incentiveSum = (Pricing.List > 0) && Array.isArray(incentivesList) ? 
    incentivesList.map(incentive => {
      return parseFloat(incentive.Amount)
    }).reduce((prev, curr) => {
      return prev + curr
    }, 0) : 0

  const renderIncentives = () => (
    (incentiveSum > 0) &&
    <div style={{ marginBottom: "1em" }}>
      <hr style={{ width: "100%" }} />
      {incentivesList.map(incentive => (
        <div className={style["vdp-incentives"]}>
          <div style={{ position: "relative", paddingRight: "24px", paddingTop: "10px" }}>
            <p>{incentive.OfferName}</p>
            {
              incentive.OfferDescription.length > 0 &&
              <>
                <span className={style["incentive-info"]}>
                </span>
                <div className={style["incentive-detail"]}>
                  <p>{incentive.OfferDescription}</p>
                  {incentive.OfferEndDate === "" ? <span>See dealer for more details and offer end date.</span> :  <span>Offer valid through: {incentive.OfferEndDate.substring(0, 10)} See dealer for more details.</span> }
                </div>
              </>
            }
          </div>
          <span>{`-$${incentive.Amount}`}</span>
        </div>
      ))
      }
      <hr style={{ width: "100%" }} />
    </div>
  )

  return [renderIncentives, Number(incentiveSum)]
}