import React, { useContext } from 'react'
import { Link } from "gatsby"

import AutoCheckButton from '../autocheckButton/AutoCheckButton'
import { FinanceCalculatorContext } from '../modal/financing/storage/CalculatorContext'

import { captureEvent } from 'event-service/modules/capture'

import unavaiable from '../../images/rv-not-available.png'

import { IsNewDisplay, IsCertifiedDisplay, configurableString, configurableLink, configurableSlug } from '../../Modules/configurableVehicleInfo'
import useIncentives from '../vdp/hooks/VDPIncentives'
import InnerHTML from 'dangerously-set-html-content'
import style from "../../scss/srp-side-style.scss"

export default function VehicleRecommendation(props) {
  const { theSlug, item, formatPrice, SRPCardBtns, ShowLocationFilter, HideNewUsed, strikeMSRP, banner, overwriteCTAs, staticBtns, additionalInventory} = props
  const { unavailableImg, AutoCheckEnabled } = props
  const { retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText } = props
  const isFavorite = props.isFavorite || false
  const favorite = props.favorite || (() => { })
  const location = typeof window !== 'undefined' && window.location
  const fallbackText = props.fallbackText
  const [, setFinanceModalContext] = useContext(FinanceCalculatorContext)
  const [, incentivesSum] = useIncentives(item.ListOfAppliedOffers,item.Pricing)

  const contextualizeItem = () => {
    const { Pricing, VehicleInfo } = item
    const finalPrice = Number(Pricing.ExtraPrice1 || Pricing.List)
    const vehicleInfo = VehicleInfo
    setFinanceModalContext({ finalPrice: finalPrice - incentivesSum, vehicleInfo })
  }

  const { Pricing, VehicleInfo } = item
  const finalPrice = Number(Pricing.ExtraPrice1 || Pricing.List)
  const configurablePriceInfo = finalPrice - incentivesSum;
  
  const dataLayer = (...args) => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(...args);
    }
  };

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  const showPhoto = () => {
    let mainPhoto = {}
    if (item.MainPhotoUrl === "") {
      mainPhoto = unavailableImg
    } else {
      mainPhoto = item.MainPhotoUrl;
    }
    return mainPhoto
  }
  const renderHTMLBtn = (html) => (
    <div className="sr-html-btns" data-vehicle-info={JSON.stringify({ ...item.VehicleInfo, price: item.Pricing.ExtraPrice1 })} onClick={() => 
      dataLayer({
        'event': 'CTA_event',
        'VIN': item.VehicleInfo.VIN,
        'MSRP': item.Pricing.List,
        'reduced_price': item.Pricing.ExtraPrice1
      }
    )}>
      <InnerHTML html={configurableString(html, item.VehicleInfo, showPhoto(), configurablePriceInfo)} />
    </div>
  )

  const renderBtns = (btngroup) => (
    <div className="sr-btns">
      {(overwriteCTAs? staticBtns : SRPCardBtns).map((btngroup, index) => {
        let BtnStyle = btngroup.overrideTheme ? {
          background: btngroup.btncolor,
          border: '1px solid ' + btngroup.btntxtcolor,
          ":hover": {
            background: btngroup.btntxtcolor,
            border: '1px solid ' + btngroup.btncolor
          }
        } : {};
        let LinkStyle = btngroup.overrideTheme ? {
          color: btngroup.btntxtcolor,
          ":hover": { color: btngroup.btncolor }
        } : {};
        if (btngroup.html != undefined && btngroup.html != '' && btngroup.html != '<p></p>') {
          if (btngroup.html === "{{autocheck}}") {
            return <AutoCheckButton 
                        vin={item.VehicleInfo.VIN} 
                        IsNew={item.VehicleInfo.IsNew} 
                        AutoCheckEnabled={AutoCheckEnabled} 
                    />
        } else return renderHTMLBtn(btngroup.html) //renderHTMLBtn("<div>{{new}} {{year}} {{make}}</div>") //Test without make DM changes, Publishing, Clean & Building
        } else {
          return <div className="sr-btns__wrap">
            {
              ((btngroup.btnleft !== "") && (btngroup.btnleftlink.includes('tel:')))
                ?
                <a className="sr-btns-item" style={BtnStyle} href={btngroup.btnleftlink}
                  onClick={event => {
                  captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnleftlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnleftlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                    dataLayer({
                      'event': 'CTA_event',
                      'VIN': item.VehicleInfo.VIN,
                      'MSRP': item.Pricing.List,
                      'reduced_price': item.Pricing.ExtraPrice1
                    });
                    contextualizeItem(item);
                    setStorageItem("vehicle:vehicle", item.VehicleInfo);
                    setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                  }
                  }>
                  <button className="sr-btns-link" style={LinkStyle} >
                    {configurableString(btngroup.btnleft, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                  </button>
                </a>
                :
                (btngroup.btnleft == "")
                  ?
                  <></>
                  :
                  ((btngroup.btnleft !== "") && (btngroup.btnleftlink.includes('#')))
                    ?
                    <Link className="sr-btns-item" style={BtnStyle}
                      to={location.search + configurableString(btngroup.btnleftlink, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      onClick={event => {
                      captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnleftlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnleftlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                        dataLayer({
                          'event': 'CTA_event',
                          'VIN': item.VehicleInfo.VIN,
                          'MSRP': item.Pricing.List,
                          'reduced_price': item.Pricing.ExtraPrice1
                        });
                        contextualizeItem(item);
                        setStorageItem("vehicle:vehicle", item.VehicleInfo);
                        setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                      }
                      }>
                      <button className="sr-btns-link" style={LinkStyle} >
                        {configurableString(btngroup.btnleft, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      </button>
                    </Link>
                    :
                    <Link className="sr-btns-item" style={BtnStyle}
                      to={configurableLink(btngroup.btnleftlink, item.VehicleInfo)}
                      onClick={event => {
                      captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnleftlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnleftlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                        dataLayer({
                          'event': 'CTA_event',
                          'VIN': item.VehicleInfo.VIN,
                          'MSRP': item.Pricing.List,
                          'reduced_price': item.Pricing.ExtraPrice1
                        });
                        contextualizeItem(item);
                        setStorageItem("vehicle:vehicle", item.VehicleInfo);
                        setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                      }
                      }>
                      <button className="sr-btns-link" style={LinkStyle} >
                        {configurableString(btngroup.btnleft, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      </button>
                    </Link>
            }
            {
              ((btngroup.btnright !== "") && (btngroup.btnrightlink.includes('tel:')))
                ?
                <a className="sr-btns-item" style={BtnStyle} href={btngroup.btnrightlink}
                  onClick={event => {
                  captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnrightlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnrightlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                    dataLayer({
                      'event': 'CTA_event',
                      'VIN': item.VehicleInfo.VIN,
                      'MSRP': item.Pricing.List,
                      'reduced_price': item.Pricing.ExtraPrice1
                    });
                    contextualizeItem(item);
                    setStorageItem("vehicle:vehicle", item.VehicleInfo);
                    setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                  }
                  }>
                  <button className="sr-btns-link" style={LinkStyle} >
                    {configurableString(btngroup.btnright, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                  </button>
                </a>
                :
                (btngroup.btnright == "")
                  ?
                  <></>
                  :
                  ((btngroup.btnright !== "") && (btngroup.btnrightlink.includes('#')))
                    ?
                    <Link className="sr-btns-item" style={BtnStyle}
                      to={location.search + configurableString(btngroup.btnrightlink, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      onClick={event => {
                      captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnrightlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnrightlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                        dataLayer({
                          'event': 'CTA_event',
                          'VIN': item.VehicleInfo.VIN,
                          'MSRP': item.Pricing.List,
                          'reduced_price': item.Pricing.ExtraPrice1
                        });
                        contextualizeItem(item);
                        setStorageItem("vehicle:vehicle", item.VehicleInfo);
                        setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                      }
                      }>
                      <button className="sr-btns-link" style={LinkStyle} >
                        {configurableString(btngroup.btnright, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      </button>
                    </Link>
                    :
                    <Link className="sr-btns-item" style={BtnStyle} to={configurableString(btngroup.btnrightlink, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      onClick={event => {
                      captureEvent({
                      event: 'asc_cta_interaction',
                      element_text: `${event.currentTarget.innerText}`,
                      element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                      element_type: 'button',
                      event_action: 'click',
                      event_action_result: `${btngroup.btnrightlink.startsWith("#") ? 'open form' : 'open'}`,
                      link_url: `${btngroup.btnrightlink}`,
                      item_id: item.VehicleInfo.VIN,
                      item_number: `${item.VehicleInfo.Year} ${item.VehicleInfo.Make} ${item.VehicleInfo.Model} ${item.VehicleInfo.Trim}`,
                      item_price: `${item.Pricing.ExtraPrice1}`,
                      item_condition: `${item.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                      item_year: `${item.VehicleInfo.Year}`,
                      item_make: `${item.VehicleInfo.Make}`,
                      item_model: `${item.VehicleInfo.Model}`,
                      item_variant: `${item.VehicleInfo.Trim}`,
                      item_color: `${item.VehicleInfo.ExteriorColor}`,
                      item_type: 'vehicle',
                      item_category: `${item.VehicleInfo.BodyStyle}`,
                      item_fuel_type: `${item.VehicleInfo.EngineFuelType}`,
                      item_inventory_date: `${item.VehicleInfo.InStockDate}`
                    }, {
                      handlers: ['ASC']
                    }).then(res => console.log(res)).catch(err => console.log(err))
                        dataLayer({
                          'event': 'CTA_event',
                          'VIN': item.VehicleInfo.VIN,
                          'MSRP': item.Pricing.List,
                          'reduced_price': item.Pricing.ExtraPrice1
                        });
                        contextualizeItem(item);
                        setStorageItem("vehicle:vehicle", item.VehicleInfo);
                        setStorageItem("vehicle:price", (item.Pricing.ExtraPrice1 != 0 ? item.Pricing.ExtraPrice1 : "Call for Price"))
                      }
                      }>
                      <button className="sr-btns-link" style={LinkStyle} >
                        {configurableString(btngroup.btnright, item.VehicleInfo, showPhoto(), configurablePriceInfo)}
                      </button>
                    </Link>
            }
          </div>
        }
      })}
    </div >
  )

  const FLAGS = { msrp: 1, msrpSale: 2, sale: 4, both: 8, incentives: 16 }
  const renderPrice = (msrp, sale) => {
    const toRender = 0
    if (item.hasOwnProperty("ListOfAppliedOffers") && incentivesSum > 0) {
      return toRender | FLAGS.incentives
    } else if (msrp == 0 && sale == 0) {
      return toRender | FLAGS.both
    }  else if (msrp == sale) {
      return toRender | FLAGS.msrp
    } else if (msrp > sale) {
      return toRender | FLAGS.msrpSale
    } else if (msrp < sale) {
      return toRender | FLAGS.sale
    }
  }

  const renderMSRPRetailLabel = (isNew,Pricing) => {
    return isNew ? "MSRP" : Pricing.List > Pricing.ExtraPrice1 ? retailPriceDiscountCustomText : retailPriceCustomText
  }
  
  const pricing = renderPrice(item.Pricing.List, item.Pricing.ExtraPrice1)

   
  return (
    <>
      <div className="sr-item-slider">
      <Link to={configurableSlug(theSlug, item.VehicleInfo)}>
      { banner !== null && banner.placement == "top" && <div className={"ribbon__top-center" + " " + "special-ribbon__wrapper"}>
          <div className="special-ribbon" style={{"backgroundColor" : banner.bgColor}}>
            <span className="special-text" style={{"color" : banner.textColor}}>{banner.text}</span>
          </div>
        </div>
        || banner !== null && banner.placement == "diagLeft" && <div className={"ribbon__top-left" + " " + "special-ribbon__wrapper"}>
        <div className="special-ribbon" style={{"backgroundColor" : banner.bgColor}}>
            <span className="special-text" style={{"color" : banner.textColor}}>{banner.text}</span>
          </div>
      </div>
        || banner !== null && banner.placement == "diagRight" && <div className={"ribbon__top-right" + " " + "special-ribbon__wrapper"}>
        <div className="special-ribbon" style={{"backgroundColor" : banner.bgColor}}>
            <span className="special-text" style={{"color" : banner.textColor}}>{banner.text}</span>
        </div>
      </div>
          || banner !== null && banner.placement == "bottom" && <div className={"ribbon__bottom-center" + " " + "special-ribbon__wrapper"}>
          <div className="special-ribbon" style={{"backgroundColor" : banner.bgColor}}>
              <span className="special-text" style={{"color" : banner.textColor}}>{banner.text}</span>
          </div>
      </div>
        }
          <img  alt={configurableSlug("{{year}} {{make}} {{model}}", item.VehicleInfo)} className="sr-item__img" src={showPhoto()} onError={handleImgError} />
          <div className="sr-item__more-btn">More Details</div></Link>
        <div className={`sr-item__like ${isFavorite && "sr-item__like_active"}`}
          onClick={() => favorite(item.VehicleInfo.VIN)} />
        {/* <ul className="sr-item-slider__dots">
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot"></li>
          <li className="sr-item-slider__dot sr-item-slider__dot_active"></li>
        </ul>*/}
      </div>
        <Link to={configurableSlug(theSlug, item.VehicleInfo)}><div className="sr-item-content">
        <div className="sr-item-content__upper">
        <h2 className="sr-item-content__header">
          <span className="sr-item-content__header sr-item-content_font-normal">{(HideNewUsed ? "" :(IsCertifiedDisplay(item.VehicleInfo.IsCertified) + IsNewDisplay(item.VehicleInfo.IsNew))) + " " + item.VehicleInfo.Year}</span> {item.VehicleInfo.Make + " " + item.VehicleInfo.Model + " " + item.VehicleInfo.Trim}
        </h2>
        <p className="sr-item-content__stock">
          {ShowLocationFilter && <>
            <span className="sr-item-content_font-normal">Location:</span> <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.DealerName}</span>
            <br/>
          </>}
          <span className="sr-item-content_font-normal">VIN:</span> <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.VIN}</span>
	  <br/>
          <span className="sr-item-content_font-normal">Mileage:</span> <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.Mileage}</span>
	  &nbsp;&#9679;&nbsp;
          <span className="sr-item-content_font-normal">Stock #:</span> <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.StockNumber}</span>
        </p>
        <p className="sr-item-content__specs">
          <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.Drivetrain}</span>{item.VehicleInfo.Drivetrain.length > 0 ? ',': ''} <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.Transmission}</span>{item.VehicleInfo.Transmission.length > 0 ? ',': ''} <span className="sr-item-content_font-normal sr-item-content-value">{item.VehicleInfo.Engine}</span>
        </p>
	      </div>

          { false /*Changed to false so Used vehicles hit the same logic as New, but with the label flipped*//*!item.VehicleInfo.IsNew*/ 
          ? (!additionalInventory ?
              <div className="sr-item-content__costs">
                <div className="sr-item-content__costs-new-cost">
                  <h3 className="sr-item-content__cost-header-single">Dealer Price</h3>
                  <p className="sr-item-content__cost-single">
                    {formatPrice(item.Pricing.ExtraPrice1 - incentivesSum, fallbackText)}
                  </p>
                </div>

              </div> 
              : <></>)
              : (!additionalInventory ?
                <div className="sr-item-content__costs">
                {Boolean(pricing & FLAGS.both) &&
                <div className="sr-item-content__costs-msrp">
                  <h3 className="sr-item-content__cost-header-single">Dealer Price</h3>
                  <p className="sr-item-content__cost-single">{fallbackText}</p>
                </div>
                }
                {Boolean(pricing & FLAGS.msrp || pricing & FLAGS.msrpSale) && ( item.Pricing.ExtraPrice1 == 0 || item.Pricing.ExtraPrice1 == item.Pricing.List) &&
                <div className="sr-item-content__costs-msrp ">
                  <h3 className="sr-item-content__cost-header-single">{renderMSRPRetailLabel(item.VehicleInfo.IsNew,item.Pricing)}</h3>
                  <p className="sr-item-content__cost-single">
                    {formatPrice(item.Pricing.List, fallbackText)}
                  </p>
                </div>
                }
                {Boolean(pricing & FLAGS.msrp || pricing & FLAGS.msrpSale) &&  item.Pricing.ExtraPrice1 > 0 && item.Pricing.ExtraPrice1 != item.Pricing.List &&
                <div className={"sr-item-content__costs-msrp " + ((item.VehicleInfo.IsNew && strikeMSRP) ? "strike-through": "")}>
                  <h3 className="sr-item-content__cost-header-single">{renderMSRPRetailLabel(item.VehicleInfo.IsNew,item.Pricing)}</h3>
                  <p className="sr-item-content__cost-single" style={{ fontSize: "1.25em", opacity: "0.55" }}>
                    {formatPrice(item.Pricing.List, fallbackText)}
                  </p>
                </div>
                }
                {Boolean(pricing & FLAGS.sale || pricing & FLAGS.msrpSale) &&  item.Pricing.ExtraPrice1 > 0 && item.Pricing.List >= item.Pricing.ExtraPrice1 &&
                <div className="sr-item-content__costs-msrp">
                  <h3 className="sr-item-content__cost-header-single">Dealer Price</h3>
                  <p className="sr-item-content__cost-single" style={{ fontSize: "1.6em", opacity: "0.8" }}>
                    {formatPrice(item.Pricing.ExtraPrice1 - incentivesSum, fallbackText)}
                  </p>
                </div>
                }
                {Boolean(pricing & FLAGS.sale || pricing & FLAGS.msrpSale) &&  item.Pricing.ExtraPrice1 > 0  && item.Pricing.List < item.Pricing.ExtraPrice1 &&
                <div className="sr-item-content__costs-msrp">
                  <h3 className="sr-item-content__cost-header-single">Dealer Price</h3>
                  <p className="sr-item-content__cost-single">
                    {formatPrice(item.Pricing.ExtraPrice1 - incentivesSum, fallbackText)}
                  </p>
                </div>
                }
                {Boolean(pricing & FLAGS.incentives) &&
                  <>
                    <div className={"sr-item-content__costs-msrp " + ((item.VehicleInfo.IsNew && strikeMSRP) ? "strike-through": "")}>
                      <h3 className="sr-item-content__cost-header-both">{renderMSRPRetailLabel(item.VehicleInfo.IsNew,item.Pricing)}</h3>
                      <p className="sr-item-content__cost-both" style={{ fontSize: "1.25em", opacity: "0.55" }}>
                        {formatPrice(item.Pricing.List, fallbackText)}
                      </p>
                    </div>
                    <div className="sr-item-content__costs-msrp">
                      <h3 className="sr-item-content__cost-header-both">Dealer Price</h3>
                      <p className="sr-item-content__cost-both"  style={{ opacity: "0.8" }}>
                        {formatPrice(item.Pricing.List - incentivesSum - (item.Pricing.List - item.Pricing.ExtraPrice1), fallbackText)}
                      </p>
                    </div>
                  </>
                }
              </div>
              : <></>)
          }

      </div>
      </Link>
      {renderBtns(item)}
    </>
  )
}

VehicleRecommendation.defaultProps = {
  unavailableImg: unavaiable
}
