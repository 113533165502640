import React from 'react';
import autocheckImage from "../../images/autocheck-icon.png"
import { siteMetadata } from '../../../gatsby-config';

class AutoCheckButton extends React.Component {
  onClick = () => {
    const { vin } = this.props;
    const url = `/autocheck/?vin=${vin}`;
    window.open(url, "_blank");
  }

  render() {
    const { IsNew, AutoCheckEnabled } = this.props;

    return (
      !IsNew && AutoCheckEnabled ? (
        <div className='autocheck-wrapper'>
          <img
            style={{ cursor: 'pointer' }}
            className="autocheck-image"
            src={autocheckImage}
            alt="autocheck"
            onClick={this.onClick}
          />
        </div>
        
      ) : null
    );
  }
}

export default AutoCheckButton;
