import React, { useState } from "react";
import { Link } from "gatsby";
import axios from "axios";
import styles from "./QuickView.module.scss";

const QuickView = (props) => {
    const { VehicleSlug, VehicleImage } = props;
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [data, setData] = useState(null);
    const [discount, setDiscount] = useState(false);
    const [appliedOffers, setAppliedOffers] = useState(false);
    const [availableOffers, setAvailableOffers] = useState(false);
    const [savings, setSavings] = useState(0);
    const [offMSRP, setOffMSRP] = useState(0);

    function fetchQuickViewData(e) {
        e.preventDefault();
        setLoading(true);

        function extractTextFromHTML(htmlString) {
            const doc = new DOMParser().parseFromString(htmlString, 'text/html');
            return doc.body.textContent || "";
        }

        try {
            axios.get(`/page-data${VehicleSlug}page-data.json`)
            .then(res => {
                console.log(res.data.result);
                const { pageContext } = res.data.result
                setData({
                    image: '',
                    title: `New ${pageContext.vehicle.VehicleInfo.Year} ${pageContext.vehicle.VehicleInfo.Make} ${pageContext.vehicle.VehicleInfo.Model} ${pageContext.vehicle.VehicleInfo.Trim}`,
                    vin: pageContext.vehicle.VehicleInfo.VIN,
                    stock: pageContext.vehicle.VehicleInfo.StockNumber,
                    msrp: pageContext.vehicle.Pricing.List,
                    price: pageContext.vehicle.Pricing.ExtraPrice1,
                    priceLabel: pageContext.netPriceCustomText || "VIP Pricing*",
                    disclaimer: extractTextFromHTML(pageContext?.disclaimer),
                    discounts: pageContext.vehicle.ListOfAppliedOffers || [],
                    offers: pageContext.vehicle.ListOfAvailableOffers.filter(offer => !offer.Name.includes("Enterprise")) || []
                });
                
                if (pageContext.vehicle.ListOfAppliedOffers.length > 0) setAppliedOffers(true);
                if (pageContext.vehicle.Pricing.List !== pageContext.vehicle.Pricing.ExtraPrice1) setDiscount(true);
                if (pageContext.vehicle.ListOfAvailableOffers.length > 0) setAvailableOffers(true);

                // Calculate savings
                let savings = 0;

                // Calculate offers savings if they exist
                if (pageContext.vehicle.ListOfAppliedOffers.length > 0) {
                    pageContext.vehicle.ListOfAppliedOffers.forEach(offer => {
                        savings += offer.Amount;
                    });
                }
                
                // If there's a dealer discount, add it to savings
                if (pageContext.vehicle.Pricing.List !== pageContext.vehicle.Pricing.ExtraPrice1) {
                    savings += (pageContext.vehicle.Pricing.List - pageContext.vehicle.Pricing.ExtraPrice1);
                } 
                // If there's no dealer discount and no applied offers, set savings to 0
                else if (savings === 0) {
                    savings = 0;
                }
                
                setSavings(savings);
                setOffMSRP(pageContext.vehicle.Pricing.List - savings);


                console.log(res.data.result.pageContext);
                setModal(true);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }

    }

    function showOfferDetails(e) {
        e.preventDefault();
        let name = e.currentTarget.id;
        document.getElementById(`${name}-disclaimer`).style.display = "block";
    }

    function hideOfferDetails(e) {
        e.preventDefault();
        let name = e.currentTarget.id;
        document.getElementById(`${name}-disclaimer`).style.display = "none";
    }

    function formatCurrency(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    const QuickViewModal = () => {
        return (
            <div className={styles["quick-view-container"]} onClick={(e) => e.preventDefault()}>
                <div className={styles["quick-view-modal"]}>
                    <div className={styles["quick-view-modal-top"]}>
                        <div>
                            <p>Details</p>
                        </div>

                        <div onClick={(e) => { e.preventDefault(); setModal(false); }}>
                            <p>X</p>
                        </div>
                    </div>
                    <div className={styles["quick-view-modal-middle"]} onScroll={(e) => e.stopPropagation()}>
                        <div className={styles["quick-view-modal-middle-vehicle"]}>
                            <div className={styles["quick-view-modal-middle-vehicle-image"]}>
                                <img src={VehicleImage} />
                            </div>

                            <div className={styles["quick-view-modal-middle-vehicle-info"]}>
                                <h2>{data.title}</h2>
                                <p>VIN: {data.vin}</p>
                                <p>STOCK: {data.stock}</p>
                            </div>
                        </div>
                        <div className={styles["quick-view-modal-middle-pricing"]}>
                            {/*Pricing Title*/}
                            {(discount || appliedOffers) && <h3>Detailed Pricing</h3>}

                            {/*MSRP*/}
                            <div className={styles["quick-view-modal-middle-pricing-msrp"]}>
                                <p style={{textDecoration: ((discount || appliedOffers) ? "line-through" : "none")}}>MSRP</p>
                                <p style={{textDecoration: ((discount || appliedOffers) ? "line-through" : "none")}}>${formatCurrency(data.msrp)}</p>
                            </div>

                            {/*Dealer Discount*/}
                            {(discount && data.msrp > 0 && data.price > 0) &&
                                <>
                                    {((data.msrp - data.price) < 0) ? 
                                        <div className={styles["quick-view-modal-middle-pricing-discount"]}>
                                            <p>Dealer Accessories</p>
                                            <p>+${formatCurrency(data.msrp - data.price).replace('-', '')}</p>
                                        </div>
                                    :
                                    <div className={styles["quick-view-modal-middle-pricing-discount"]}>
                                        <p>Dealer Discount</p>
                                        <p>-${formatCurrency(data.msrp - data.price)}</p>
                                    </div>
                                    }
                                </>
                            }

                            {/*Applied Offers*/}
                            {appliedOffers &&
                                <>  
                                    <hr/>
                                    <p className={styles["quick-view-modal-middle-pricing-savings-title"]}>Applied Savings</p>
                                    <div className={styles["quick-view-modal-middle-pricing-discounts"]}>
                                        {data.discounts.map((discount, index) => {
                                            return (
                                                <>
                                                    <div className={styles["quick-view-modal-middle-pricing-discounts-row"]}>
                                                        {(discount.OfferDescription && (discount.OfferDescription.length > 0)) &&
                                                            <div> 
                                                                <span id={`discount-${index}`} className={styles['incentive-info']} onMouseEnter={(e) => showOfferDetails(e)} onMouseOut={(e) => hideOfferDetails(e)}></span>
                                                            </div>
                                                        }
                                                        <div className={styles["quick-view-modal-middle-pricing-discounts-row-name"]}>
                                                            <p>{discount.OfferName}</p>         
                                                        </div>
                                                        
                                                        <p className={styles["quick-view-modal-middle-pricing-discounts-row-amount"]}>-${discount.Amount}</p>
                                                    </div>

                                                    <div id={`discount-${index}-disclaimer`} style={{display: "none"}} className={styles["incentive-disclaimer"]}>
                                                        <p>{discount.OfferDescription ? discount.OfferDescription : ''} </p>
                                                        {discount.OfferEndDate === "" ? 
                                                            <p>See dealer for more details and offer end date.</p>
                                                            :  <p>Offer valid through: {new Date(discount.OfferEndDate).toLocaleDateString('en-US')} See dealer for more details.</p> 
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </>
                            }

                            {/*Total Savings*/}
                            {((discount || appliedOffers) && (data.price !== 0)) &&
                                <>
                                    {!appliedOffers && <hr/>}
                                    <div className={styles["quick-view-modal-middle-pricing-list"]}>
                                        <p>{data.priceLabel}</p>
                                        <p>${formatCurrency(offMSRP)}</p>
                                    </div>

                                    {savings > 0 &&
                                        <div className={styles["quick-view-modal-middle-pricing-total-savings"]}>
                                            <p>Total Savings</p>
                                            <p>${formatCurrency(savings)}</p>
                                        </div>
                                    }
                                </>
                            }

                            {/*Available Offers*/}
                            {availableOffers &&
                            <>
                                <hr/>
                                <p className={styles["quick-view-modal-middle-pricing-savings-title"]}>Additional Savings - Conditional Offers</p>
                                {(data.offers.length > 0) &&
                                    <div className={styles["quick-view-modal-middle-pricing-offers"]}>
                                        {data.offers.map((offer, index) => {
                                            if (!offer.Description || offer.Description === "") return null;
                                            if (offer.Price === "$0") return null;
                                            return (
                                                <>
                                                    <div className={styles["quick-view-modal-middle-pricing-offers-row"]}>
                                                        {(offer.Description && (offer.Description.length > 0)) &&
                                                            <div> 
                                                                <span id={`offer-${index}`} className={styles['incentive-info']} onMouseEnter={(e) => showOfferDetails(e)} onMouseOut={(e) => hideOfferDetails(e)}></span>
                                                            </div>
                                                        }
                                                        <div className={styles["quick-view-modal-middle-pricing-offers-row-name"]}>
                                                            <p>{offer.Name}</p>      
                                                        </div>
                                                        <p className={styles["quick-view-modal-middle-pricing-offers-row-amount"]}>{offer.Price}</p>
                                                    </div>
                                                    
                                                    <div id={`offer-${index}-disclaimer`} style={{display: "none"}} className={styles["incentive-disclaimer"]}>
                                                        <p>{offer.Description ? offer.Description : ''}</p>
                                                        {offer.OfferEndDate === "" ? 
                                                            <p>See dealer for more details and offer end date.</p>
                                                            :  <p>Offer valid through: {offer.OfferEndDate} See dealer for more details.</p> 
                                                        }
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                }
                            </>}
                        </div>
                        <div className={styles["quick-view-modal-middle-disclaimer"]}>
                            <p><strong>Disclaimer:</strong></p>
                            <br/>
                            <p>{data.disclaimer}</p>
                        </div>
                    </div>
                    <div className={styles["quick-view-modal-bottom"]}>
                        <Link to={VehicleSlug}>Details</Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="quick-view-srp-container">
            {
                !modal ? 
                    (loading ? <p>Loading...</p> : <>
                        <span onClick={(e) => fetchQuickViewData(e)} style={{position: "absolute", top: "0", bottom: "0", left: "0", right: "0", zIndex: "9999"}}></span>
                        <div className={styles["quick-view-srp-button-container"]}>
                            <p className={styles["quick-view-srp-button"]}>DETAILS/MORE SAVINGS</p>
                            <span style={{width: "12px"}} className={styles['incentive-info']}></span>
                        </div>
                    </>): 
                <QuickViewModal />
            }
        </div>    
    )
}

export default QuickView;
